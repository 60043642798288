import React, { useState } from 'react';
import { Button } from '@mui/material';
import { fetchUtils, useNotify } from 'react-admin';
import { apiUrl } from '../config/http-client';
import { REQUESTS_ROUTES } from '../config/constants/apiRoutes';

export const RefreshTuneEntitiesButton = ({ style, label = 'Refresh Tune Entities' }) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleRefreshTuneEntities = () => {
    setLoading(true);
    fetchUtils.fetchJson(`${apiUrl}/${REQUESTS_ROUTES.REFRESH_TUNE_ENTITIES}`, {
      method: 'POST'
    })
    .then(({ json }) => {
      if (json.status === 'error') {
        notify(json.message, { type: 'error' });
      } else {
        notify('Tune entities refreshed successfully', { type: 'success' });
      }
    })
    .catch(() => {
      notify('Failed to refresh tune entities', { type: 'error' });
    })
    .finally(() => {
      setLoading(false);
    });
  };

  return (
    <Button
      style={style}
      variant="contained"
      color="secondary"
      onClick={handleRefreshTuneEntities}
      disabled={loading}
    >
      {loading ? 'Updating...' : label}
    </Button>
  );
};
